import * as React from "react";
import type { HeadFC } from "gatsby";
import { ProgramWindow } from "../components/gui/organisms/program-window/program-window";
import { StaticImage } from "gatsby-plugin-image";
import { DesktopId } from "../app/desktops";
import { DesktopLayoutFloating } from "../components/layout/desktop-layouts/desktop-layout-floating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  setHasOpenedMainMenu,
  welcomeSelector,
} from "../store/welcome/welcome-slice";
import { PostContent } from "../components/gui/atoms/post-content";
import styled from "styled-components";

// TODO: figure out how to programmatically navigate without the hacks here
export default function IndexPage() {
  return (
    <DesktopLayoutFloating desktopId={DesktopId.HOME}>
      <ProgramWindow
        windowId="splash"
        title="Simon Tang's Website"
        applyContentPadding={false}
        defaultGeometry={{
          defaultTranslateX: "-10vw",
          zIndexPriority: -1,
        }}
      >
        <StaticImage
          src="../images/dalle/art/emperor-computer-shiba-01.png"
          alt="Painting of a Chinese emperor and Shiba Inu using a computer, generated by DALLE-2"
          style={{
            width:
              "clamp(100px, min(calc(100vw - 40px), calc(100vh - 120px)), 800px)",
          }}
        />
      </ProgramWindow>

      <ProgramWindow
        windowId="home-info"
        title="Welcome"
        defaultGeometry={{
          defaultTranslateX: "max(100px, calc(16vw - 4vh))",
          defaultTranslateY: "calc(-20vh + 100px)",
        }}
        // isMinimized={hasOpenedMainMenu}
      >
        <FirstStepsContent />
      </ProgramWindow>
    </DesktopLayoutFloating>
  );
}

const StyledFirstStepsContentListItem = styled.li<{ $done: boolean }>`
  ::marker {
    content: ${(props) => (props.$done ? "'✓ '" : "")};
  }
`;

const StrikethroughMaybe = ({
  isStrike,
  children,
}: {
  isStrike: boolean;
  children: React.ReactNode;
}) => (isStrike ? <s>{children}</s> : <>{children}</>);

const FirstStepsContent = () => {
  const { hasOpenedMainMenu } = useAppSelector(welcomeSelector);
  // TEMPORARY
  const dispatch = useAppDispatch();
  window.resetWelcome = () => {
    dispatch(setHasOpenedMainMenu(false));
  };
  return (
    <PostContent>
      <p>Let's get started:</p>
      <ul>
        <StyledFirstStepsContentListItem $done={hasOpenedMainMenu}>
          <StrikethroughMaybe isStrike={hasOpenedMainMenu}>
            Press{" "}
            <strong
              style={{ border: "1px solid currentcolor", padding: "2px 8px" }}
            >
              <FontAwesomeIcon icon={faBars} /> Menu
            </strong>{" "}
            to begin.
          </StrikethroughMaybe>
        </StyledFirstStepsContentListItem>
      </ul>
    </PostContent>
  );
};

export const Head: HeadFC = () => <title>Home | simontang.dev</title>;
